import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import Snackbar from "./Snackbar";

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2
  },
  form: {
    textAlign: "left"
  }
});

class EmailCapture extends Component {
  state = { email: "", submitted: false, showError: false, errorMessage: "" };
  emailCaptureUrl = `${process.env.REACT_APP_API_PATH}lists/subscribe`;

  onChange = e => {
    this.setState({ email: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { email } = this.state;
    const { listContactSource } = this.props;

    const data = {
      email: email,
      source: 'master',
      list_contact_source: listContactSource
    };

    if (window.claim && window.claim.dealId) {
      data['deal_id'] = window.claim.dealId
    }

    fetch(this.emailCaptureUrl, {
      method: "post",
      body: JSON.stringify(data),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).then(
      response => {
        if (response.ok) {
          this.setState({ submitted: true });
        } else {
          response.json().then(({ error }) => {
            this.setState({
              showError: true,
              errorMessage: error
            });
          });
        }
      },
      fetchError => {
        // try again, probably network error
        this.setState({
          showError: true,
          errorMessage: "Something went wrong."
        });
      }
    );
  };

  onCloseSnackbar = () => {
    this.setState({ showError: false });
  };

  render() {
    const { classes } = this.props;
    const { submitted } = this.state;

    return (
      <Card className={classes.card}>
        <Snackbar
          variant="error"
          open={this.state.showError}
          message={this.state.errorMessage}
          onClose={this.onCloseSnackbar}
        />
        <Typography component="h1" variant="subtitle2" color="textSecondary">
          {submitted
            ? "Your email has been added to the list."
            : "Get notified about upcoming campaigns."}
        </Typography>
        {!submitted && (
          <form
            className={classes.form}
            onSubmit={this.onSubmit}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Email"
              value={this.state.email}
              onChange={this.onChange}
              placeholder="Enter your email address"
              margin="normal"
              variant="outlined"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                )
              }}
            />

            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
              type="submit"
            >
              Get Notified
            </Button>
          </form>
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(EmailCapture);
