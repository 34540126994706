import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Navbar } from '.'

const styles = theme => ({
  title: {
    marginBottom: '2rem'
  }
});

const IPLimit = ({ classes }) => (
  <Fragment>
    <Navbar />
    <Typography component="h1" variant="h6" className={classes.title} gutterBottom>
      You've reached the maximum number of claims permitted from your network's IP address.
    </Typography>
  </Fragment>
);

export default withStyles(styles)(IPLimit);
