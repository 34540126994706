import { Component } from "react";
import { withRouter } from "react-router-dom";
import GoogleTagManager, { dataLayerPush } from "../util/GoogleTagManager";

class PageTracker extends Component {
  componentDidMount() {
    const { history } = this.props;

    GoogleTagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      gtmAuth: process.env.REACT_APP_GTM_AUTH,
      gtmEnv: process.env.REACT_APP_GTM_ENV,
    })

    this.trackPage();
    history.listen((location, action) => {
      this.trackPage();
    });
  }

  trackPage = () => {
    dataLayerPush({ event: 'page_view' });
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(PageTracker);
