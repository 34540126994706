import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import RewardCard from "../RewardCard";
import SentRewardDetails from "../SentRewardDetails";

const styles = theme => ({
  root: {},
  title: {
    marginBottom: '2rem'
  }
});

class ViewReward extends Component {
  render() {
    const { classes } = this.props;

    const {
      content: { image: salesDemoClaimImage }
    } = window.claim;

    return (
      <div className={classes.root}>
        <div>
          <RewardCard />

          {salesDemoClaimImage.src ? (
            <a href={salesDemoClaimImage.href}>
              <img
                src={salesDemoClaimImage.src}
                alt={salesDemoClaimImage.alt_text}
              />
            </a>
          ) : (
            <SentRewardDetails />
          )}
        </div>
      </div>
    );
  }
}

  ViewReward.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewReward);
