export const titleize = str =>
  str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const duration = value => {
  if (value <= 60) {
    return `${value} seconds`;
  }

  let minutes = Math.floor(value / 60);
  let seconds = value - minutes * 60;

  if (seconds > 0) {
    return `${minutes + 1} minutes`;
  }

  return `${minutes} minutes`;
};
