import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { titleize } from "../util";
import EmailCapture from "./EmailCapture";
import { Navbar } from './'

const styles = theme => ({
  button: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  logo: {
    height: "10rem",
    marginBottom: theme.spacing.unit * 3
  },
  title: {
    marginBottom: '2rem'
  }
});

// TODO: customizations
const AlreadyClaimed = ({ classes }) => {
  const {
    reward: { viewRewardButtonText },
    campaign: { kind },
    user: { email }
  } = window.claim;

  const orderUrl = `${process.env.REACT_APP_API_PATH}orders/${
    window.claim.reward.orderId
  }/t/${window.claim.reward.receiptToken}`;

  return (
    <Fragment>
      <Navbar />
      <Typography component="h1" variant="h6" className={classes.title} gutterBottom>
        This reward has already been claimed!
      </Typography>
      <Typography
        component="h2"
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
      >
        This reward has already been claimed by {email}.
      </Typography>

      <Button
        href={orderUrl}
        variant="contained"
        size="large"
        color="primary"
        className={classes.button}
      >
        {viewRewardButtonText || `View My ${titleize(kind)}`}
      </Button>

      <EmailCapture listContactSource="already_claimed" />
    </Fragment>
  );
};

export default withStyles(styles)(AlreadyClaimed);
