import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  finePrint: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    ...theme.overrides.FinePrint.root
  }
});

const FinePrintElement = ({ classes }) => {
  const {
    reward: { claimShowFinePrint, finePrint }
  } = window.claim;

  return claimShowFinePrint ? (
    <Typography className={classes.finePrint} variant="caption">
      <div dangerouslySetInnerHTML={{ __html: finePrint }} />
    </Typography>
  ) : null;
};

FinePrintElement.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FinePrintElement);
