import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Navbar } from './'

const styles = theme => ({
  button: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  }
});

const InvalidAgent = ({ classes }) => {
  const {
    reward: { claimToken },
    dealId
  } = window.claim;

  return (
    <>
      <Navbar />
      <form action={`/${dealId}/${claimToken}`} method="post">
        <Typography component="h1" variant="h6" gutterBottom>
          Click the button below to claim.
        </Typography>
        <input type="hidden" name="confirmed" value="1" />
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
        >
          Claim Now!
        </Button>
      </form>
    </>
  );
};

export default withStyles(styles)(InvalidAgent);
