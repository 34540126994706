import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Navbar } from './'

const styles = theme => ({
  img: {
    maxWidth: "100%"
  },
  button: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  logo: {
    height: "10rem",
    marginBottom: theme.spacing.unit * 3
  },
  title: {
    marginBottom: '2rem'
  },
  bodyText: {
    marginTop: theme.spacing.unit * 2
  }
});

const AllClaimed = ({ classes }) => {
  const {
    content: {
      tooSlow: {
        text,
        redirectUrl,
        redirectLabel,
        consolation: subTitle,
        image: { imageUrl, imageAlt },
        subButton,
      }
    },
  } = window.claim;

  return (
    <Fragment>
      <Navbar />
      <Typography component="h1" variant="h6" className={classes.title} gutterBottom>
        {text ||
          "Thanks for participating! We're sorry, all rewards have been claimed."}
      </Typography>
      <Typography
        component="h2"
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: subTitle
        }}
      />

      {imageUrl && (
        <img className={classes.img} src={imageUrl} alt={imageAlt} />
      )}

      {redirectUrl && (
        <Button
          href={redirectUrl}
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
        >
          {redirectLabel}
        </Button>
      )}
      {subButton && (
        <Typography className={classes.bodyText} component="h3" variant="body2" dangerouslySetInnerHTML={{ __html: subButton }} >
        </Typography>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(AllClaimed);
