import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Snackbar from "./Snackbar";
import { Navbar } from "./";

const styles = theme => ({
  input: {
    width: "100%"
  },
  button: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  },
  noThanksText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.2rem",
    color: "#83899e",
    fontWeight: "700",
    textDecoration: "underline",
    cursor: "pointer"
  },
  optIntoSmsCheckbox: {
    paddingTop: "0"
  },
  optIntoSmsCopy: {
    fontSize: "1rem",
    lineHeight: "1.5em"
  },
  optIntoSmsSwitch: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: theme.spacing.unit * 4
  },
  finePrint: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    ...theme.overrides.FinePrint.root
  },
  title: {
    marginBottom: "2rem"
  }
});

class SmsOptIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: ""
    };
  }

  orderUrl = `${process.env.REACT_APP_API_PATH}orders/${window.claim.reward.orderId}/t/${window.claim.reward.receiptToken}`;

  viewRewardButtonUrl =
    window.claim.reward.viewRewardButtonCustomUrl || this.orderUrl;

  listSubscribeUrl = `${window.location.origin}/list_subscribe`;

  onCloseSnackbar = () => {
    this.setState({ showError: false });
  };

  onSubmit = e => {
    e.preventDefault();

    const {
      reward: { orderId, receiptToken },
      brand: { id: merchantId },
      content: {
        smsList: { confirm }
      }
    } = window.claim;

    if (window.confirm(confirm)) {
      const data = {
        sentAt: new Date().toISOString(),
        merchantId: merchantId,
        orderId: orderId,
        receiptToken: receiptToken
      };

      if (process.env.NODE_ENV === "development") {
        console.log("WOULD HAVE POSTED FOR SMS OPTIN", data);
        return this.props.history.push("view-reward");
      }

      fetch(this.listSubscribeUrl, {
        method: "post",
        body: JSON.stringify(data),
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(
        response => {
          if (!response.ok) {
            response.json().then(({ error }) => {
              this.setState({
                showError: true,
                errorMessage: error
              });
            });
            return;
          }
          this.props.history.push("view-reward");
        },
        fetchError => {
          // try again, probably network error
          this.setState({
            showError: true,
            errorMessage: "Something went wrong."
          });
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    const {
      content: {
        smsList: { title, subtitle, legal, button }
      }
    } = window.claim;

    return (
      <>
        <Navbar />
        <Snackbar
          variant="error"
          open={this.state.showError}
          message={this.state.errorMessage}
          onClose={this.onCloseSnackbar}
        />
        <Typography
          component="h1"
          variant="h6"
          className={classes.title}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>

        <Typography className={classes.finePrint} variant="caption">
          <div dangerouslySetInnerHTML={{ __html: legal }} />
        </Typography>

        <form onSubmit={this.onSubmit}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            fullWidth
          >
            {button}
          </Button>
          <Link
            component={RouterLink}
            to="view-reward"
            className={classes.noThanksText}
            underline="none"
          >
            No Thanks
          </Link>
        </form>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(SmsOptIn));
