import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FinePrint from "../FinePrint";
import { titleize } from "../../util";
import { ClaimContext } from "../../ClaimProvider";

const styles = theme => ({
  button: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  },
  finePrint: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    ...theme.overrides.FinePrint.root
  },
  bodyText: {
    marginBottom: theme.spacing.unit * 4
  }
});

const SentRewardDetails = ({ classes }) => {
  const {
    reward: {
      orderId,
      receiptToken,
      viewRewardButtonText,
      textAboveRewardButton,
      sentRewardText: defaultSentRewardText,
      viewRewardButtonCustomUrl,
      previewUrl
    },
    campaign: { kind },
    user: { email: defaultEmail, phone }
  } = window.claim;

  // SendRewardForm could modify window.claim.user.email, so hasFakeSSOEmail could be true
  // despite having an updated, non-sso email value. However, reloading this page would
  // retain the original sso value, so this is a workaround to ensure we never display an sso email
  const email = defaultEmail.includes('sso.quikly.com') ? null : defaultEmail;

  const orderUrl = previewUrl || `${process.env.REACT_APP_API_PATH}orders/${orderId}/t/${receiptToken}`

  const viewRewardButtonUrl = viewRewardButtonCustomUrl || orderUrl;

  const { sentSms } = useContext(ClaimContext);

  const sentRewardText = (email || sentSms) ? (defaultSentRewardText || `We sent your ${kind} to: `) : '';

  return (
    <>
      <Typography component="h1" variant="subtitle1" gutterBottom>
        {sentRewardText}
      </Typography>
      <Typography component="h2" variant="body1" gutterBottom>
        {email}
      </Typography>
      {sentSms && (
        <Typography component="h2" variant="body1" gutterBottom>
          {phone}
        </Typography>
      )}

      {textAboveRewardButton && (
        <Typography className={classes.bodyText} component="h3" variant="body2">
          <div dangerouslySetInnerHTML={{ __html: textAboveRewardButton }} />
        </Typography>
      )}

      <Button
        href={viewRewardButtonUrl}
        variant="contained"
        size="large"
        color="primary"
        className={classes.button}
      >
        {viewRewardButtonText || `View My ${titleize(kind)}`}
      </Button>

      <FinePrint />
    </>
  );
};

export default withStyles(styles)(withRouter(SentRewardDetails));
