import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Navbar } from './'

const styles = theme => ({
  logo: {
    height: "10rem",
    marginBottom: theme.spacing.unit * 3
  },
  title: {
    marginBottom: '2rem'
  }
});

const DealNotFound = ({ classes }) => {
  return (
    <Fragment>
      <Navbar />
      <Typography component="h1" variant="h6" className={classes.title} gutterBottom>
        That is not the correct link to claim.
      </Typography>
      <Typography
        component="h2"
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
      >
        Please make sure the link is <b>EXACTLY</b> as appears in your
        notification. Claim links are always in the format:
      </Typography>
      <Typography
        component="h2"
        variant="subtitle1"
        color="textSecondary"
        gutterBottom
      >
        claim.quikly.com/<b>(number)</b>/<b>(secret code)</b>
      </Typography>
      <Typography
        component="h2"
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
      >
        Please check your code and try again!
      </Typography>
    </Fragment>
  );
};

export default withStyles(styles)(DealNotFound);
