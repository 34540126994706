const GoogleTagManager = {
  initialize: ({ gtmId, gtmAuth, gtmEnv, dataLayer }) => {
    const s = document.createElement('script');
    let gtmParams = '';
    if (gtmAuth) {
      gtmParams += `&gtm_auth=${gtmAuth}`;
    }
    if (gtmEnv) {
      gtmParams += `&gtm_preview=${gtmEnv}&gtm_cookies_win=x`;
    }

    s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmParams}';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}${gtmParams}"\
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    if (dataLayer) {
      const dataScript = document.createElement('script');
      dataScript.innerHTML = `window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(${JSON.stringify(dataLayer)});`;
      document.head.insertBefore(dataScript, document.head.childNodes[0]);
    }
    document.head.insertBefore(s, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);
  },
};

const dataLayerPush = args => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(args);
};

export default GoogleTagManager;

export { dataLayerPush };
