import React, { useState, createContext } from "react";

export const ClaimContext = createContext();

export default function ClaimProvider({ children }) {
  const [sentSms, updateSentSms] = useState(false);

  return (
    <ClaimContext.Provider
      value={{
        sentSms,
        updateSentSms
      }}
    >
      {children}
    </ClaimContext.Provider>
  );
}
